import React, { useEffect, useRef, useState } from 'react';

import {
  Checkbox,
  CheckboxGroup,
  Divider,
  MobileDrawer,
  Popover,
  PopoverTriggerAction as TriggerAction,
  Text,
  TextButton,
  TextButtonPriority as TEXT_BUTTON_PRIORITY,
} from 'wix-ui-tpa';

import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import CaretDown from 'wix-ui-icons-common/on-stage/ChevronDown';

import { classes, st } from './Filters.st.css';
import { FiltersDataHooks } from './dataHooks.const';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import {
  FilterOption,
  FilterTypes,
  FilterViewModel,
} from '../../../ViewModel/filterViewModel/filterViewModel';
import {
  WidgetComponents,
  WidgetElements,
} from '../../../../../utils/bi/consts';
import { useSettingsParams } from '../../../Hooks/useSettingsParams';
import { SrOnly } from '../../../../../utils/accessibility/SrOnly/SrOnly';

type ContentProps = {
  toggleOption: Function;
  options: FilterOption[];
  note?: string;
};

type CTAProps = {
  id: FilterTypes;
  selectedOptions: FilterOption[];
  label: string;
  open: boolean;
  onClick: () => void;
  ctaRef: React.MutableRefObject<any>;
};

type FilterProps = {
  filterViewModel: FilterViewModel;
};

const getSelectedOptionsFromList = (list: FilterOption[]) =>
  list.filter((option) => option.selected);

const toggleOptionInList = (
  list: FilterOption[],
  clickedOption: FilterOption,
) =>
  clickedOption.selected
    ? list.filter(
        (option) => option.selected && option.value !== clickedOption?.value,
      )
    : list.filter(
        (option) => option.selected || option.value === clickedOption?.value,
      );

const Filter: React.FC<FilterProps> = ({ filterViewModel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null) as React.MutableRefObject<any>;
  const ctaRef = useRef(null) as React.MutableRefObject<any>;
  const escPressedRef = useRef(null) as React.MutableRefObject<any>;
  const { isMobile, isRTL } = useEnvironment();
  const { onFilterChanged, onElementClicked } = useCalendarActions();
  const PopoverElement = Popover.Element!;
  const PopoverContent = Popover.Content!;
  const { options, id, label, note } = filterViewModel;
  const hasChildren = options[0].children !== undefined;
  const selectedOptions = getSelectedOptions();

  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => popoverRef.current.focus());
    } else if (escPressedRef.current) {
      escPressedRef.current = false;
      ctaRef.current.focus();
    }
  }, [isOpen]);

  const setFilterOptionsVisibility = (isVisible: boolean) => {
    onElementClicked(WidgetComponents.FILTER, WidgetElements.FILTER_BUTTON, {
      filterType: id,
    });
    setIsOpen(isVisible);
  };

  const onEscPress = () => {
    escPressedRef.current = true;
    setFilterOptionsVisibility(false);
  };

  const onClick = () => {
    setFilterOptionsVisibility(!isOpen);
  };

  function getSelectedOptions() {
    return hasChildren
      ? options.reduce(
          (acc, option) =>
            acc.concat(getSelectedOptionsFromList(option.children!)),
          [] as FilterOption[],
        )
      : getSelectedOptionsFromList(options);
  }

  const toggleOption = (clickedOption: FilterOption) => {
    const optionsToSelect = hasChildren
      ? options.reduce((acc, option) => {
          if (option.value === clickedOption.value) {
            return option.selected ? acc : acc.concat(option.children!);
          } else if (option.children?.includes(clickedOption)) {
            return acc.concat(
              toggleOptionInList(option.children!, clickedOption),
            );
          } else {
            return acc.concat(getSelectedOptionsFromList(option.children!));
          }
        }, [] as FilterOption[])
      : toggleOptionInList(options, clickedOption);

    onElementClicked(WidgetComponents.FILTER, WidgetElements.CHECKBOX);
    onFilterChanged(
      id,
      optionsToSelect.map(({ value }) => value),
    );
  };

  const cta = (
    <CTA
      id={id}
      selectedOptions={selectedOptions}
      label={label}
      open={isOpen}
      onClick={onClick}
      ctaRef={ctaRef}
    />
  );

  const content = (
    <Content toggleOption={toggleOption} options={options} note={note} />
  );

  return isMobile ? (
    <div className={classes.filterWrapper}>
      {cta}
      <div className={classes.mobileWrapper}>
        <MobileDrawer isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
          <div className={classes.mobileContentWrapper}>
            <Text className={classes.mobileContentLabel}>{label}</Text>
            {content}
          </div>
        </MobileDrawer>
      </div>
    </div>
  ) : (
    <>
      <Popover
        // @ts-expect-error
        ref={popoverRef}
        shown={isOpen}
        placement={isRTL ? 'bottom-end' : 'bottom-start'}
        dynamicWidth
        triggerAction={TriggerAction.click}
        showArrow={false}
        onClickOutside={() => setFilterOptionsVisibility(false)}
        onEscPress={() => onEscPress()}
        onTabOut={() => setFilterOptionsVisibility(false)}
        className={classes.popover}
        tabIndex={-1}
        role="group"
        aria-labelledby={id}
        data-hook={id}
      >
        <PopoverElement>
          <div className={classes.filterWrapper}>{cta}</div>
        </PopoverElement>
        <PopoverContent>
          <div className={classes.desktopContentWrapper}>{content}</div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export type FiltersProps = {
  filterViewModels: FilterViewModel[];
};

const CTA: React.FC<CTAProps> = ({
  open,
  onClick,
  selectedOptions,
  label,
  id,
  ctaRef,
}) => {
  const { t } = useTranslation();

  function getSelectedValue() {
    switch (selectedOptions.length) {
      case 0:
        return t('filter.all-options.label');
      case 1:
        return selectedOptions[0].label;
      default:
        return String(selectedOptions.length);
    }
  }

  const getLabel = () =>
    t('filter.cta.label', { label, value: getSelectedValue() });

  return (
    <div data-hook={FiltersDataHooks.FILTER_CTA}>
      <TextButton
        id={id}
        ref={ctaRef}
        aria-label={label}
        aria-haspopup="dialog"
        aria-expanded={open}
        data-hook={FiltersDataHooks.FILTER_CTA + id}
        className={st(classes.filterCTA, { open })}
        priority={TEXT_BUTTON_PRIORITY.secondary}
        suffixIcon={<CaretDown className={classes.filterCTASuffix} />}
        onClick={onClick}
        contentClassName={classes.filterCTAContent}
      >
        {getLabel()}
      </TextButton>
    </div>
  );
};

const FilterOptionCheckbox = ({
  option,
  indented,
  toggleOption,
}: {
  option: FilterOption;
  indented?: boolean;
  toggleOption: ContentProps['toggleOption'];
}) => {
  const { value, selected, indeterminate, label, srOnlyLabel, children } =
    option;

  return (
    <Checkbox
      data-hook={FiltersDataHooks.OPTION + value}
      className={st(classes.item, {
        indented: Boolean(indented),
        parent: Boolean(children),
      })}
      onChange={() => toggleOption(option)}
      checked={selected}
      indeterminate={indeterminate}
      key={value}
      label={
        srOnlyLabel ? (
          <span>
            <span aria-hidden>{label}</span>
            <SrOnly data-hook={FiltersDataHooks.ALL_SR_LABEL}>
              {srOnlyLabel}
            </SrOnly>
          </span>
        ) : (
          label
        )
      }
    />
  );
};

const Content: React.FC<ContentProps> = ({ toggleOption, options, note }) => {
  const { experiments } = useExperiments();
  const isAccessibleFiltersEnabled = experiments.enabled(
    'specs.bookings.accessibleFilters',
  );

  return (
    <>
      <CheckboxGroup className={classes.checkboxGroup}>
        {isAccessibleFiltersEnabled ? (
          <ul>
            {options.map((option) => (
              <li key={option.value}>
                <FilterOptionCheckbox
                  option={option}
                  toggleOption={toggleOption}
                  indented={false}
                />
                {option.children && (
                  <ul>
                    {option.children.map((child) => (
                      <li key={child.value}>
                        <FilterOptionCheckbox
                          option={child}
                          toggleOption={toggleOption}
                          indented={true}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        ) : (
          options.map((option) => (
            <Checkbox
              data-hook={FiltersDataHooks.OPTION + option.value}
              className={st(classes.item)}
              onChange={() => toggleOption(option)}
              checked={option.selected}
              key={option.value}
              label={option.label}
            />
          ))
        )}
      </CheckboxGroup>
      {note && (
        <>
          <Divider
            data-hook={FiltersDataHooks.NOTE_DIVIDER}
            className={classes.noteDivider}
          />
          <Text data-hook={FiltersDataHooks.NOTE}>{note}</Text>
        </>
      )}
    </>
  );
};

const Filters: React.FC<FiltersProps> = ({ filterViewModels }) => {
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  if (filterViewModels.length) {
    return (
      <>
        <Divider
          data-hook={FiltersDataHooks.FILTER_DIVIDER}
          className={classes.divider}
        />
        <div
          data-hook={FiltersDataHooks.FILTERS_WRAPPER}
          className={st(classes.root, {
            direction: isMobile ? 'column' : 'row',
            alignment: settings.get(settingsParams.textAlignment),
            isMobile,
          })}
          role="group"
          aria-label={t('filters.aria-label')}
        >
          <Text className={classes.filterBy} data-hook={FiltersDataHooks.LABEL}>
            {t('filters.filter-by.label')}
          </Text>
          {filterViewModels.map((filterViewModel) => (
            <Filter
              key={filterViewModel.id}
              filterViewModel={filterViewModel}
            />
          ))}
        </div>
      </>
    );
  }
  return null;
};

export default Filters;
