import React from 'react';
import { Text, Divider } from 'wix-ui-tpa';
import { classes, st } from './SectionHeader.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { DataHooks } from './constants';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useSettingsParams } from '../../Hooks/useSettingsParams';

export type SectionHeaderProps = {
  title: string;
  suffix?: React.ReactNode;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, suffix }) => {
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const settingsParams = useSettingsParams();

  return (
    <>
      <div
        className={st(classes.root, {
          isMobile,
          isRTL,
          alignment: settings.get(settingsParams.textAlignment),
          withSuffix: !!suffix,
        })}
      >
        <Text
          data-hook={DataHooks.Title}
          className={classes.title}
          tagName={AccessibilityHtmlTags.SecondaryHeading}
        >
          {title}
        </Text>
        {suffix}
      </div>
      <Divider className={classes.divider} data-hook={DataHooks.Divider} />
    </>
  );
};

export default SectionHeader;
