export enum FiltersDataHooks {
  FILTERS_WRAPPER = 'filter-wrapper',
  FILTER_DIVIDER = 'filter-header-divider',
  LABEL = 'filter-label',
  FILTER_CTA = 'filter-cta',
  OPTION = 'filter-option',
  NOTE_DIVIDER = 'filters-note-divider',
  NOTE = 'filters-note',
  ALL_SR_LABEL = 'filters-sr-All',
}
