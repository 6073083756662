import React, { useMemo } from 'react';
import { DatePickerDateIndicationProps as dateIndicationProps } from 'wix-ui-tpa';
import { ReactComponent as DotIcon } from '../../../../../assets/Icons/DotIndication.svg';
import { getLocalDateTimeStartOfDay } from '../../../../../utils/dateAndTime/dateAndTime';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import { rfcToShiftedDate } from '@wix/bookings-date-time';
import { TriggeredByOptions } from '../../../../../types/types';
import {
  DateAvailabilityStatus,
  DatePickerViewModel,
} from '../../../ViewModel/datePickerViewModel/datePickerViewModel';
import { DatePickerDataHooks } from './constants';
import { classes, st } from './DatePicker.st.css';

const DotIndication: React.FC<{
  dateAvailabilityStatus?: DateAvailabilityStatus;
  shouldShow: boolean;
}> = (
  { dateAvailabilityStatus, shouldShow } = {
    dateAvailabilityStatus: undefined,
    shouldShow: false,
  },
) => {
  return (
    <div
      className={st(classes.dateIndicationContainer, {
        visible: shouldShow,
      })}
    >
      <DotIcon
        data-hook={DatePickerDataHooks.DOT_ICON}
        className={
          dateAvailabilityStatus === DateAvailabilityStatus.HAS_AVAILABLE_SLOTS
            ? classes.availableDateIndication
            : classes.notAvailableDateIndication
        }
      />
    </div>
  );
};

export const useDatePicker = (datePickerViewModel: DatePickerViewModel) => {
  const { onDateSelected } = useCalendarActions();
  const {
    selectedDate: localDateTime,
    dateAvailabilityStatuses,
    firstDayOfTheWeek,
    todayLocalDateTime,
    locale,
    accessibility,
  } = datePickerViewModel;

  const onDateChange = async (date: Date) => {
    const localDateTimeStartOfDay = getLocalDateTimeStartOfDay(date);
    await onDateSelected(
      localDateTimeStartOfDay,
      TriggeredByOptions.DATE_SELECTED,
    );
  };

  const dateIndications = ({ date, isSelected }: dateIndicationProps) => {
    const dateAvailabilityStatus = dateAvailabilityStatuses.get(
      getLocalDateTimeStartOfDay(date),
    );
    return (
      <DotIndication
        dateAvailabilityStatus={dateAvailabilityStatus}
        shouldShow={!!dateAvailabilityStatus && !isSelected}
      />
    );
  };

  const selectedDate = useMemo(
    () => rfcToShiftedDate(localDateTime),
    [localDateTime],
  );
  const today = useMemo(
    () => rfcToShiftedDate(todayLocalDateTime),
    [todayLocalDateTime],
  );

  return {
    onDateChange,
    dateIndications,
    selectedDate,
    today,
    accessibility,
    firstDayOfTheWeek,
    locale,
  };
};
