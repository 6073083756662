import React, { useEffect, useState } from 'react';
import { Text, Spinner, SpinnerTypes } from 'wix-ui-tpa';
import { TimePickerAccessibility } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import { AccessibilityHtmlTags } from '../../../../../utils/accessibility/constants';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { FlowElements, useFlow } from '../../../Hooks/useFlow';
import { SlotsStatus } from '../../../../../types/types';
import { SrOnly } from '../../../../../utils/accessibility/SrOnly/SrOnly';
import TimeSlotsNotification from '../../TimeSlotsNotification/TimeSlotsNotification';
import { classes, st } from './TimePicker.st.css';
import { DataHooks } from './constants';
import { TimeSlotsNotificationViewModel } from '../../../ViewModel/timeSlotsNotificationViewModel/timeSlotsNotificationViewModel';

export type TimePickerProps = {
  status: SlotsStatus;
  date?: string;
  timeSlotsNotificationViewModel?: TimeSlotsNotificationViewModel;
  accessibility: TimePickerAccessibility;
  availableSlotsComponent: React.ReactNode;
  noAvailableSlotsComponent: React.ReactNode;
};

const SelectedDate: React.FC<{ date: string }> = ({ date }): JSX.Element => {
  return (
    <Text
      id="selected-date"
      data-hook={DataHooks.SELECTED_DATE}
      className={st(classes.formattedSelectedDate)}
      tagName={AccessibilityHtmlTags.Paragraph}
      aria-hidden="true"
    >
      {date}
    </Text>
  );
};

const Loader: React.FC = () => {
  return (
    <div data-hook={DataHooks.LOADER} className={classes.spinnerWrapper}>
      <Spinner
        className={classes.spinner}
        diameter={24}
        isCentered={true}
        type={SpinnerTypes.slim}
      />
    </div>
  );
};

const TimePicker: React.FC<TimePickerProps> = ({
  status,
  date,
  timeSlotsNotificationViewModel,
  accessibility,
  availableSlotsComponent,
  noAvailableSlotsComponent,
}) => {
  const { isMobile, isRTL } = useEnvironment();

  const timePickerRef = useFlow(FlowElements.TIME_PICKER);
  const [srOnlyAnnouncement, setSROnlyAnnouncement] = useState('');

  useEffect(() => {
    setSROnlyAnnouncement('');
    if (status !== SlotsStatus.LOADING) {
      setTimeout(
        () =>
          setSROnlyAnnouncement(accessibility.onTimePickerLoadedAnnouncement),
        300,
      );
    }
  }, [status, accessibility.onTimePickerLoadedAnnouncement]);

  return (
    <div
      className={st(classes.root, {
        isMobile,
        isRTL,
      })}
      ref={timePickerRef}
    >
      <SrOnly
        id="announcement"
        role="alert"
        data-hook={DataHooks.SR_ONLY_ANNOUNCEMENT}
      >
        {srOnlyAnnouncement}
      </SrOnly>
      <div
        data-hook={DataHooks.TIME_PICKER}
        role="region"
        aria-labelledby="selected-date"
      >
        <SelectedDate date={date!} />
        {status === SlotsStatus.LOADING ? <Loader /> : null}
        {status === SlotsStatus.AVAILABLE_SLOTS ? (
          <div>
            <TimeSlotsNotification viewModel={timeSlotsNotificationViewModel} />
            {availableSlotsComponent}
          </div>
        ) : null}
        {status === SlotsStatus.NO_AVAILABLE_SLOTS
          ? noAvailableSlotsComponent
          : null}
      </div>
    </div>
  );
};

export default TimePicker;
