import React from 'react';
import { DailyAgendaWeeklyPickerLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
import TimePicker from '../TimePicker/TimePicker';
import WeeklyDatePicker from '../DatePicker/WeeklyDatePicker/WeeklyDatePicker';
import { DataHooks } from './constants';
import { AgendaSlot } from '../../AgendaSlot/AgendaSlot';
import { classes } from './DailyAgendaWeeklyPickerLayout.st.css';
import { Text } from 'wix-ui-tpa';

export interface DailyAgendaWeeklyPickerLayoutProps {
  viewModel: DailyAgendaWeeklyPickerLayoutViewModel;
  errorNotificationText: string;
}

const DailyAgendaWeeklyPickerLayout: React.FC<
  DailyAgendaWeeklyPickerLayoutProps
> = ({
  viewModel: { datePickerViewModel, timePicker },
  errorNotificationText,
}) => {
  const {
    status,
    formattedSelectedDate,
    accessibility,
    slots,
    noAvailableSlotsViewModel: { noSessionsOfferedText },
  } = timePicker;

  const NoAvailableSlots = () => (
    <Text data-hook={DataHooks.NoAvailableSlots} className={classes.noSlots}>
      {noSessionsOfferedText}
    </Text>
  );

  return (
    <div data-hook={DataHooks.Layout}>
      <ErrorNotification errorText={errorNotificationText} />
      <div className={classes.root}>
        <WeeklyDatePicker viewModel={datePickerViewModel} />
        <TimePicker
          accessibility={accessibility}
          status={status}
          date={formattedSelectedDate}
          availableSlotsComponent={slots.map((slot) => (
            <AgendaSlot slot={slot} key={slot.id} />
          ))}
          noAvailableSlotsComponent={<NoAvailableSlots />}
        />
      </div>
    </div>
  );
};

export default DailyAgendaWeeklyPickerLayout;
