import React, { useState, useEffect } from 'react';

import {
  BoxSelection,
  BoxSelectionBoxSize as BoxSize,
  Text,
  TextButtonPriority as TEXT_BUTTON_PRIORITY,
  TextButton,
} from 'wix-ui-tpa';

import {
  TimeSlotsSelectionViewModel,
  TimeSlot,
} from '../../ViewModel/timeSlotsSelectionViewModel/timeSlotsSelectionViewModel';
import { classes, st } from './TimeSlotsSelection.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { DataHooks, TimeSlotsSelectionDisplayMode } from './constants';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';

export type TimeSlotsSelectionProps = {
  viewModel: TimeSlotsSelectionViewModel;
  displayMode: TimeSlotsSelectionDisplayMode;
  date: string;
};

export const TIME_DELAY_BEFORE_FOCUS = 0;

const TimeSlotsSelection: React.FC<TimeSlotsSelectionProps> = ({
  viewModel,
  displayMode,
  date,
}) => {
  const {
    timeSlots,
    shouldLimitNumberOfTimeSlotsDisplayed,
    maxNumberOfTimeSlotsToDisplay,
    showAllButtonText,
    highlightedSlotDetails,
  } = viewModel;
  const boxSelectionRef = React.useRef(null as any);
  const { onTimeSelected, onElementClicked } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const [numberOfTimeSlotsToDisplay, setNumberOfTimeSlotsToDisplay] =
    useState(0);
  const shouldDisplayShowAllButton =
    timeSlots.length > numberOfTimeSlotsToDisplay;
  const defaultNumberOfSlotsToDisplay = shouldLimitNumberOfTimeSlotsDisplayed
    ? maxNumberOfTimeSlotsToDisplay
    : timeSlots.length;

  useEffect(() => {
    setNumberOfTimeSlotsToDisplay(defaultNumberOfSlotsToDisplay);
  }, [
    defaultNumberOfSlotsToDisplay,
    maxNumberOfTimeSlotsToDisplay,
    shouldLimitNumberOfTimeSlotsDisplayed,
  ]);

  useEffect(() => {
    if (highlightedSlotDetails.shouldFocusOnRender) {
      setTimeout(
        () =>
          boxSelectionRef.current?.focus?.(highlightedSlotDetails.slotIndex),
        TIME_DELAY_BEFORE_FOCUS,
      );
    }
  }, [highlightedSlotDetails]);

  const onShowAllClick = () => {
    setNumberOfTimeSlotsToDisplay(timeSlots.length);
    onElementClicked(
      WidgetComponents.TIME_PICKER,
      WidgetElements.SHOW_ALL_SESSIONS_BUTTON,
    );
  };

  useEffect(() => {
    boxSelectionRef.current?.focus?.(defaultNumberOfSlotsToDisplay);
  }, [defaultNumberOfSlotsToDisplay, numberOfTimeSlotsToDisplay]);

  return (
    <div className={st(classes.root, { isMobile, displayMode })}>
      <BoxSelection
        key={`${DataHooks.TimeSlotsSelection}-${date}`}
        name={`${DataHooks.TimeSlotsSelection}-${date}`}
        data-hook={`${DataHooks.TimeSlotsSelection}-${date}`}
        className={classes.boxSelection}
        size={BoxSize.small}
        onChange={({ id }) => onTimeSelected(id)}
        ref={boxSelectionRef}
      >
        {timeSlots
          .slice(0, numberOfTimeSlotsToDisplay)
          .map((timeSlot: TimeSlot, index: number) => {
            const {
              selected,
              allSlotsAreFull,
              tooLateToBookAllSlots,
              tooEarlyToBookAllSlots,
              withWaitingList,
            } = timeSlot.status;
            const isTimeSlotFull = allSlotsAreFull && !withWaitingList;
            const isDisabled =
              isTimeSlotFull || tooLateToBookAllSlots || tooEarlyToBookAllSlots;
            return (
              <BoxSelection.Option
                key={index}
                id={timeSlot.rfcStartTime}
                className={classes.boxSelectionOption}
                aria-label={timeSlot.ariaLabel}
                checked={selected}
                disabled={isDisabled}
                unavailable={isTimeSlotFull || tooLateToBookAllSlots}
              >
                <Text
                  data-hook={`${DataHooks.TimeSlotText}-${index}`}
                  className={st(classes.boxSelectionText, {
                    isDisabled,
                  })}
                >
                  <div aria-hidden={true} className={classes.startTime}>
                    {timeSlot.formattedStartTime}
                  </div>
                  {withWaitingList ? (
                    <div aria-hidden={true} className={classes.waitingList}>
                      {viewModel.waitlistText}
                    </div>
                  ) : null}
                </Text>
              </BoxSelection.Option>
            );
          })}
      </BoxSelection>
      {shouldDisplayShowAllButton ? (
        <TextButton
          key={`${DataHooks.ShowAllButton}-${date}`}
          data-hook={`${DataHooks.ShowAllButton}-${date}`}
          priority={TEXT_BUTTON_PRIORITY.link}
          onClick={onShowAllClick}
          className={classes.showAllButton}
        >
          {showAllButtonText}
        </TextButton>
      ) : null}
    </div>
  );
};

export default TimeSlotsSelection;
