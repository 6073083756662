import { useContext } from 'react';
import { isWeeklyTimeTableWidget } from '../../../utils/presets';
import calendarSettingsParams from '../settingsParams';
import weeklyTimetableSettingsParams from '../../WeeklyTimetable/settingsParams';
import { PresetContext } from './usePreset';

export const useSettingsParams = () => {
  const preset = useContext(PresetContext);

  if (isWeeklyTimeTableWidget(preset)) {
    return weeklyTimetableSettingsParams;
  }

  return calendarSettingsParams;
};
